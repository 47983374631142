import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Link from 'next/link';
import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import ArrowIcon from '@/assets/icons/ic-arrow.svg';

import { FilledButton } from '@/components/core/Button/variants/FilledButton';
import { CloseButton } from '@/components/core/CloseButton';
import { Container } from '@/components/core/Grid';
import { H2 } from '@/components/core/Heading';
import { FadeInModalContext } from '@/components/layouts/fadeInModalContext';
import { Overlay } from '@/components/Overlay';
import { OverlayType } from '@/enums/index';
import { ButtonVariant } from '@/styles/themes/button';
import { BREAKPOINTS } from '@/styles/themes/default';
import { IThemeProps } from '@/styles/themes/types';

const closeButtonStyles = css`
  position: fixed;
  top: 20px;
  right: 20px;

  @media (min-width: ${BREAKPOINTS.Tablet.min}px) {
    right: 40px;
  }

  @media (min-width: ${BREAKPOINTS.Desktop.min}px) {
    top: 64px;
    right: 140px;
  }
`;

const Content = styled.div<IThemeProps>`
  ${H2}, p {
    margin-bottom: 40px;
  }

  @media (min-width: ${BREAKPOINTS.Tablet.min}px) {
    ${H2} {
      padding-right: 16px;
    }
  }

  @media (min-width: ${BREAKPOINTS.Desktop.min}px) {
    ${H2} {
      margin-bottom: 64px;
    }

    p {
      margin-bottom: 56px;
    }
  }
`;

const GridContainer = styled(Container)<IThemeProps>`
  height: 100%;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.legacy.colors.white};

  ${H2}, p {
    margin-bottom: 40px;
  }

  @media (min-width: ${BREAKPOINTS.Tablet.min}px) {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-gap: 16px;

    ${Content} {
      grid-column: 3 / 7;
    }
  }

  @media (min-width: ${BREAKPOINTS.Desktop.min}px) {
    grid-template-columns: repeat(12, 1fr);

    div {
      grid-column: 5 / 9;
    }
  }
`;

export const ThankYou: FC = () => {
  const { showModalType } = useContext(FadeInModalContext);
  const { t } = useTranslation();

  const handleClose = () => {
    showModalType.set(OverlayType.NONE);
  };

  return (
    <Overlay
      css={css`
        background: linear-gradient(0deg, rgba(0, 255, 224, 0.05), rgba(0, 255, 224, 0.05)), rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(75px);
      `}
    >
      <GridContainer>
        <CloseButton css={closeButtonStyles} size="large" onClose={handleClose} />

        <Content>
          <H2>{t('contactForm.thankYou.title')}</H2>

          <p>{t('contactForm.thankYou.message')}</p>

          <Link href="/" legacyBehavior>
            <FilledButton variant={ButtonVariant.ACTION_FILLED}>
              {t('contactForm.thankYou.buttonLabel')}
              <ArrowIcon
                css={css`
                  margin-left: 8px;
                `}
              />
            </FilledButton>
          </Link>
        </Content>
      </GridContainer>
    </Overlay>
  );
};
